@import "mixins";

@include darkModeAtRoot {
  .highlight table td { padding: 5px; }
  .highlight table pre { margin: 0; }
  .highlight .gh {
    color: #999999;
  }
  .highlight .sr {
    color: #f6aa11;
  }
  .highlight .go {
    color: #888888;
  }
  .highlight .gp {
    color: #555555;
  }
  .highlight .gs {
  }
  .highlight .gu {
    color: #aaaaaa;
  }
  .highlight .nb {
    color: #f6aa11;
  }
  .highlight .cm {
    color: #75715e;
  }
  .highlight .cp {
    color: #75715e;
  }
  .highlight .c1 {
    color: #75715e;
  }
  .highlight .cs {
    color: #75715e;
  }
  .highlight .c, .highlight .ch, .highlight .cd, .highlight .cpf {
    color: #75715e;
  }
  .highlight .err {
    color: #960050;
  }
  .highlight .gr {
    color: #960050;
  }
  .highlight .gt {
    color: #960050;
  }
  .highlight .gd {
    // CUSTOM: handle diff on dark mode
    color: var(--color-red);
  }
  .highlight .gi {
    // CUSTOM: handle diff on dark mode
    color: var(--color-success);
  }
  .highlight .ge {
    color: #49483e;
  }
  .highlight .kc {
    color: #66d9ef;
  }
  .highlight .kd {
    color: #66d9ef;
  }
  .highlight .kr {
    color: #66d9ef;
  }
  .highlight .no {
    color: #66d9ef;
  }
  .highlight .kt {
    color: #66d9ef;
  }
  .highlight .mf {
    color: #ae81ff;
  }
  .highlight .mh {
    color: #ae81ff;
  }
  .highlight .il {
    color: #ae81ff;
  }
  .highlight .mi {
    color: #ae81ff;
  }
  .highlight .mo {
    color: #ae81ff;
  }
  .highlight .m, .highlight .mb, .highlight .mx {
    color: #ae81ff;
  }
  .highlight .sc {
    color: #ae81ff;
  }
  .highlight .se {
    color: #ae81ff;
  }
  .highlight .ss {
    color: #ae81ff;
  }
  .highlight .sd {
    color: #e6db74;
  }
  .highlight .s2 {
    color: #e6db74;
  }
  .highlight .sb {
    color: #e6db74;
  }
  .highlight .sh {
    color: #e6db74;
  }
  .highlight .si {
    color: #e6db74;
  }
  .highlight .sx {
    color: #e6db74;
  }
  .highlight .s1 {
    color: #e6db74;
  }
  .highlight .s, .highlight .sa, .highlight .dl {
    color: #e6db74;
  }
  .highlight .na {
    color: #a6e22e;
  }
  .highlight .nc {
    color: #a6e22e;
  }
  .highlight .nd {
    color: #a6e22e;
  }
  .highlight .ne {
    color: #a6e22e;
  }
  .highlight .nf, .highlight .fm {
    color: #a6e22e;
  }
  .highlight .vc {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .nn {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .nl {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .ni {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .bp {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .vg {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .vi {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .nv, .highlight .vm {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .w {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight {
    color: #ffffff;
    background-color: #272822;
  }
  .highlight .n, .highlight .py, .highlight .nx {
    color: #ffffff;
  }
  .highlight .ow {
    color: #f92672;
  }
  .highlight .nt {
    color: #f92672;
  }
  .highlight .k, .highlight .kv {
    color: #f92672;
  }
  .highlight .kn {
    color: #f92672;
  }
  .highlight .kp {
    color: #f92672;
  }
  .highlight .o {
    color: #f92672;
  }
}
